import React from "react";
import NavBar2 from "./components/NavBar";
import NavOne from "./components/NavOne";

const NavBar = () => {
  return (
    <>
      <NavOne />
      <NavBar2 />
    </>
  );
};

export default NavBar;
