import React from "react";
import "./SessionAdmission.css";

export default function SessionAdmission() {
  return (
    <div className="container-fluid" style={{ padding: "0" }}>
      <div class="background-image">
        <div class="overlay"></div>
        <h4> Admissions Open for Upcoming Session - 2024-2025</h4>
        <h1>Any Enquiry call us : 0771-6996222 </h1>
        <p>bsspranavanandaacademy10@gmail.com</p>
      </div>
    </div>
  );
}
