import React from "react";

const BannerAdmissionPage = () => {
  return (
    <>
      <img
        src="/images/academic-banner.jpg"
        width={"100%"}
        alt="/images/academic-banner.jpg"
      />
    </>
  );
};

export default BannerAdmissionPage;
