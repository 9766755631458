import React from 'react'

const StudentsList = () => {
  return (
    <>
    <h3>students list will dislay here</h3>
    </>
  )
}

export default StudentsList
