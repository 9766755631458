import React from "react";

const BannerFacilities = () => {
  return (
    <>
      <img
        src="/images/infrastructure-banner.jpg"
        width={"100%"}
        alt="/images/infrastructure-banner"
      />
    </>
  );
};

export default BannerFacilities;
