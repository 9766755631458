import React from 'react'
import InfraSchoolDetail from './InfraSchoolDetail'
import InfraYogaPage from './InfraYogaPage'
// import LabFacilities from './LabFacilities'
import LabFacilities2 from './LabFacilities2'

const Infrastructure = () => {
  return (
    <>
    <InfraSchoolDetail/>
    <InfraYogaPage/>
    {/* <LabFacilities/> */}
    <LabFacilities2/>
    </>
  )
}

export default Infrastructure
